<template>
  <div class="fluid" id="assetMovementList">
    <div>
      <div class="asset-movement-list-div">
        <v-card class="px-3 py-2" style="overflow:hidden;">
          <v-row>
            <v-col
              cols="12"
              class="d-flex"
              style="position:relative;padding:0 20px;text-align:center;margin-top:20px;"
            >
              <h6 class="indigo--text" style="font-weight:bold;margin:auto;">
                ASSET MANAGEMENT SYSTEM
              </h6>
            </v-col>
            <v-col cols="12">
              <div class="asset-list-toolbar">
                <v-toolbar
                  class="asset-movement-list-toolbar-1"
                  flat
                  style="height: 60px; border-radius: 10px 10px 0 0"
                >
                  <v-toolbar-title>List Pemindahan Aset</v-toolbar-title>
                  <v-divider class="mx-6" inset vertical></v-divider>
                  <v-spacer></v-spacer>

                  <!-- <div style="width:200px;margin-right:10px;margin-left:90px;">
                    <v-text-field
                      class="ml-1 mr-2"
                      dense
                      label="Tanggal"
                      type="date"
                      name="startdate"
                      style="height:30px;"
                      step="1"
                    />
                  </div> -->
                  <div style="width:200px;margin-right:10px;">
                    <v-select
                      :items="dropDownCompany"
                      v-model="paramAPI.source_company_id"
                      style=" height:30px;
                        position: relative;
                        top:3px;
                        margin-left: 10px;
                        font-size: 16px;
                      "
                      label="dari"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                    ></v-select>
                  </div>
                  <div style="width:200px;margin-right:10px;">
                    <v-select
                      :items="dropDownDestination"
                      v-model="paramAPI.destination_company_id"
                      style=" height:30px;
                        position: relative;
                        top:3px;
                        margin-left: 10px;
                        font-size: 16px;
                      "
                      label="destinasi"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                    ></v-select>
                  </div>
                  <div style="width:200px;margin-right:10px;">
                    <v-select
                      :items="dropDownStatus"
                      v-model="paramAPI.status"
                      style=" height:30px;
                        position: relative;
                        top:3px;
                        margin-left: 10px;
                        font-size: 16px;
                      "
                      label="status"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                    ></v-select>
                  </div>

                  <v-btn
                    rounded
                    outlined
                    small
                    elevation="1"
                    color="indigo"
                    class="indigo--text"
                    style="font-size:12px; position:relative; left:15px;"
                    @click="searchEnter"
                    >search</v-btn
                  >
                </v-toolbar>
                <v-toolbar
                  class="asset-movement-list-toolbar-1"
                  flat
                  style="height: 60px; border-radius: 10px 10px 0 0;"
                >
                  <!-- <div style="margin-right:10px;">
                    <div style="width: 130px">
                      <v-select
                        label="Action"
                        style="
                      position: relative;
                      top: 15px;
                      margin-left: 5px;
                      font-size: 12px;
                    "
                        :items="[
                          { id: 0, name: '' },
                          { id: 1, name: 'Edit' },
                          { id: 4, name: 'Delete' },
                          { id: 5, name: 'Hide' }
                        ]"
                        item-text="name"
                        item-value="id"
                        outlined
                        return-id
                        dense
                      ></v-select>
                    </div>
                  </div> -->

                  <router-link to="/asset-management/form-pemindahan">
                    <v-btn
                      outlined
                      elevation="1"
                      color="indigo"
                      class="indigo--text font-weight-bold"
                      style="font-size:12px;margin-right:20px;"
                    >
                      Formulir Pemindahan Aset
                    </v-btn>
                  </router-link>
                  <v-divider
                    class="mx-6"
                    inset
                    vertical
                    style="border:1px solid transparent;"
                  ></v-divider>
                  <div class="asset-movement-list-toolbar-div">
                    <v-text-field
                      v-model="paramAPI.keyword"
                      label="search here"
                      type="search"
                      append-icon="mdi-magnify"
                      outlined
                      dense
                      @keyup.enter="searchEnter"
                      style="position: relative; top: 15px; width: 10px"
                    ></v-text-field>
                  </div>
                </v-toolbar>
              </div>

              <div class="asset-list-toolbar-2">
                <v-expansion-panels flat accordion>
                  <v-expansion-panel v-for="(item, i) in 1" :key="i" flat>
                    <v-expansion-panel-header
                      class="d-flex align-items-start"
                      style="padding:0 0 0 15px;"
                    >
                      <p style="color: rgba(0, 0, 0, 0.4)">
                        Tampilkan Filter & Pencarian
                      </p>
                      <template v-slot:actions>
                        <v-icon color="primary">
                          $expand
                        </v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content id="expansionPanelContent">
                      <div class="asset-list-toolbar-1">
                        <v-toolbar
                          class="asset-movement-list-toolbar-1"
                          flat
                          style="height: 60px; border-radius: 10px 10px 0 0"
                        >
                          <div>
                            <v-select
                              :items="dropDownCompany"
                              v-model="paramAPI.source_company_id"
                              style=" height:30px;
                        position: relative;
                        top:3px;
                        font-size: 16px;
                      "
                              label="dari"
                              item-text="name"
                              item-value="id"
                              return-id
                              dense
                            ></v-select>
                          </div>
                        </v-toolbar>
                        <v-toolbar
                          class="asset-movement-list-toolbar-1"
                          flat
                          style="height: 60px; border-radius: 10px 10px 0 0"
                        >
                          <div>
                            <v-select
                              :items="dropDownDestination"
                              v-model="paramAPI.destination_company_id"
                              style=" height:30px;
                        position: relative;
                        top:3px;
                        font-size: 16px;
                      "
                              label="destinasi"
                              item-text="name"
                              item-value="id"
                              return-id
                              dense
                            ></v-select>
                          </div>
                        </v-toolbar>
                        <v-toolbar
                          class="asset-movement-list-toolbar-1"
                          flat
                          style="height: 60px; border-radius: 10px 10px 0 0"
                        >
                          <div>
                            <v-select
                              :items="dropDownStatus"
                              v-model="paramAPI.status"
                              style=" height:30px;
                        position: relative;
                        top:3px;
                        font-size: 16px;
                      "
                              label="status"
                              item-text="name"
                              item-value="id"
                              return-id
                              dense
                            ></v-select>
                          </div>
                        </v-toolbar>
                      </div>
                      <v-toolbar
                        class="asset-list-toolbar-2"
                        flat
                        style="
                  border-radius: 10px 10px 0 0;
                "
                      >
                        <v-text-field
                          v-model="paramAPI.keyword"
                          label="Cari disini"
                          type="search"
                          outlined
                          dense
                          @keyup.enter="searchEnter"
                          style="position: relative; top: 15px; width: 10px"
                        ></v-text-field>
                      </v-toolbar>
                      <v-toolbar
                        class="asset-list-toolbar-2"
                        flat
                        style="
                  border-radius: 10px 10px 0 0;
                  border-bottom: 1px solid #e0e0e0; width:100%;
                "
                      >
                        <v-btn
                          rounded
                          elevation="1"
                          color="indigo"
                          class="white--text"
                          style="font-size:12px; position:relative; width:100%;"
                          @click="searchEnter"
                          >search</v-btn
                        >
                      </v-toolbar>
                      <v-toolbar
                        class="asset-list-toolbar-2"
                        flat
                        style="
                  border-radius: 10px 10px 0 0;
                  border-bottom: 1px solid #e0e0e0; width:100%;
                "
                      >
                        <router-link
                          to="/asset-management/form-pemindahan"
                          style="width:100%;"
                        >
                          <v-btn
                            outlined
                            elevation="1"
                            color="indigo"
                            class="indigo--text font-weight-bold"
                            style="font-size:12px;width:100%;"
                          >
                            Buka Formulir Pemindahan Aset
                          </v-btn>
                        </router-link>
                      </v-toolbar>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </v-col>

            <v-col cols="12" style="overflow:auto;">
              <v-data-table
                mobile-breakpoint="0"
                fixed-header
                height="49vh"
                style="cursor: pointer;min-width:1200px;"
                :headers="headers"
                :items="result"
                :items-per-page="10"
                :loading="loading"
                @click:row="rowClick"
                item-key="id"
                :show-select="false"
                :single-select="false"
              >
                <template v-slot:[`item.schedule_from`]="{ item }">
                  <div style="padding: 0; display: flex; flex-direction: row">
                    {{ convertDate(item.schedule_from) }}
                  </div>
                </template>
                <template v-slot:[`item.schedule_to`]="{ item }">
                  <div style="padding: 0; display: flex; flex-direction: row">
                    {{ convertDate(item.schedule_to) }}
                  </div>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <div
                    :style="
                      `padding: 0; display: flex; flex-direction: row; color:${color(
                        item.status
                      )};`
                    "
                  >
                    {{ movementStatus(item.status) }}
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
    <movement-detail
      :moveId="detail"
      v-if="detail !== null"
      @loadPage="initDataTable"
    />
    <div style="position:fixed; bottom:10px; right:0;">
      <Scanner />
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  components: {
    MovementDetail: () => import('./components/Detail'),
    Scanner: () => import('../../home/components/Scanner.vue')
  },
  data: () => ({
    asset: buildType.apiURL('asset'),
    isAdmin: true,
    paramAPI: {
      keyword: '',
      offset: 0,
      limit: 1000,
      status: '',
      sortBy: '',
      sortType: '',
      company_id: '',
      dept_id: '',
      destination_company_id: 0,
      source_company_id: 0
    },
    headers: [
      {
        text: 'No. Pemindahan',
        value: 'doc_no',
        align: 'left',
        sortable: false
      },
      {
        text: 'PIC',
        value: 'created_by_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Department',
        value: 'source_department_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Plant',
        value: 'source_company_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Pemindahan',
        value: 'schedule_from',
        align: 'left',
        sortable: false
      },
      {
        text: 'Destinasi',
        value: 'destination_company_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Pengembalian',
        value: 'schedule_to',
        align: 'left',
        sortable: false
      },
      // {
      //   text: 'Total Item',
      //   value: 'total_item',
      //   align: 'left',
      //   sortable: false
      // },
      {
        text: 'Status',
        value: 'status',
        align: 'left',
        sortable: false
      }
    ],
    result: [],
    detail: null,
    loading: false,
    dropDownCompany: [],
    dropDownDestination: [],
    dropDownStatus: []
  }),
  created() {
    this.dropdownCompany()
    this.dropdownDestination()
    this.dropdownStatus()
    this.initDataTable()
  },
  computed: {
    ...mapGetters(['getIsLoading', 'getUserProfile'])
  },
  watch: {
    'paramAPI.source_company_id'() {
      this.initDataTable()
    },
    'paramAPI.destination_company_id'() {
      this.initDataTable()
    },
    'paramAPI.status'() {
      this.initDataTable()
    }
  },
  methods: {
    ...mapActions([]),
    ...mapMutations(['setIsLoading']),

    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },

    dropdownCompany() {
      this.dropDownCompany = [
        { id: 0, name: 'default' },
        { id: 1, name: 'TMP' },
        { id: 2, name: 'IMP' },
        { id: 3, name: 'GIT' },
        { id: 4, name: 'KAJ' },
        { id: 5, name: 'WIM' },
        { id: 6, name: 'SMI' }
      ]
    },
    dropdownDestination() {
      this.dropDownDestination = [
        { id: 0, name: 'default' },
        { id: 1, name: 'TMP' },
        { id: 2, name: 'IMP' },
        { id: 3, name: 'GIT' },
        { id: 4, name: 'KAJ' },
        { id: 5, name: 'WIM' },
        { id: 6, name: 'SMI' }
      ]
    },
    dropdownStatus() {
      this.dropDownStatus = [
        { id: '', name: 'default' },
        { id: -2, name: 'Cancel' },
        { id: 0, name: 'Draft' },
        { id: 1, name: 'Confirmed' },
        { id: 2, name: 'Approved' }
      ]
    },
    searchEnter() {
      this.initDataTable()
    },
    initDataTable() {
      let apiLink = `${this.asset}movement/list?keyword=${this.paramAPI.keyword}&order_by=${this.paramAPI.sortBy}&order_type=${this.paramAPI.sortType}&offset=${this.paramAPI.offset}&limit=${this.paramAPI.limit}`

      if (this.paramAPI.source_company_id !== 0) {
        apiLink =
          apiLink + `&source_company_id=${this.paramAPI.source_company_id}`
      }
      if (this.paramAPI.destination_company_id !== 0) {
        apiLink =
          apiLink +
          `&destination_company_id=${this.paramAPI.destination_company_id}`
      }
      if (this.paramAPI.status !== '') {
        apiLink = apiLink + `&status=${this.paramAPI.status}`
      }

      axios
        .get(apiLink)
        .then(res => {
          this.result = res.data.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    rowClick(pValue) {
      // this.detail = pValue
      // setTimeout(() => {
      //   document.getElementById('movementDetail').click()
      // }, 500)
      this.$router.push(`/asset-management/detail-penerimaan/${pValue.id}`)
    },
    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleDateString()
        return local
        // const year = local.slice(0, local.indexOf('-'))
        // const month = local.slice(
        //   local.indexOf('-') + 1,
        //   local.lastIndexOf('-')
        // )
        // const day = local.slice(local.lastIndexOf('-') + 1)

        // return (day + '-' + month + '-' + year).replace(/-/g, '/')
      }
    },
    movementStatus(v) {
      switch (v) {
        case -2:
          return 'Canceled'

        case 1:
          return 'Pending'

        case 2:
          return 'Approved'

        default:
          return 'null'
      }
    },
    color(v) {
      switch (v) {
        case -2:
          return 'red'

        case 1:
          return 'grey'

        case 2:
          return 'green'

        default:
          return 'black'
      }
    }
  }
}
</script>

<style lang="scss">
#assetMovementList {
  .asset-movement-list-div {
    padding: 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .asset-movement-list-toolbar-1 {
      display: flex;
      flex-direction: column;
      .asset-movement-list-toolbar-div {
        width: 30%;
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
    .asset-list-toolbar-1 {
      display: none;
    }
    .asset-list-toolbar-2 {
      display: none;
    }
  }
}

.arch-add-new-btn {
  padding: 1px 4px;
  box-shadow: 1px 1px 1px rgb(134, 132, 132);
  border: 1px solid #cacad4;
  cursor: pointer;
  margin-top: 5px;
  text-align: left;
  font-size: 12px;
  &:hover {
    box-shadow: 1px 2px 7px rgb(134, 132, 132);
    color: blue;
    text-decoration: underline;
  }
}

.arch-expDate {
  cursor: pointer;
  height: 40px;
  .arch-exp-date {
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    .arch-exp-date-notif {
      overflow: hidden;
      width: 120px;
      position: absolute;
      left: 0;
      .exp-date-notif-div {
        display: flex;

        .exp-date-notif-chip1 {
          width: 95px;
          font-size: 10px;
        }
        .exp-date-notif-chip2 {
          font-size: 10px;
          .date-notif-chip2-p {
            width: 70px;
            margin: 0;
            margin: auto;
            text-align: center;
            // padding: 0 7px;
          }
        }
      }
    }
  }

  &:hover .arch-exp-date-notif {
    width: 0;
    transition: 0.5s;
  }
}

#expansionPanelContent > * {
  padding: 0px;
}
@media (max-width: 768px) {
  #assetMovementList {
    .asset-movement-list-div {
      .asset-movement-list-toolbar-1 {
        .asset-movement-list-toolbar-div {
          display: none;
        }
      }
      .asset-list-toolbar {
        display: none;
      }
      .asset-list-toolbar-1 {
        display: block;
      }
      .asset-list-toolbar-2 {
        display: block;
      }
    }
  }
}
</style>
